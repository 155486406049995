export default function splideGeneralOptions(perPage = 4) {
    return {
        pagination: true,
        arrows: true,
        cover: true,
        gap: '5rem',
        padding: '0.4rem',
        perPage: perPage <= 4 ? perPage : 4,
        breakpoints: {
            1950:{
                perPage: 3,
            },
            1560: {
                perPage: 2,
                gap: '.7rem',
            },
            1024: {
                perPage: 1,
                gap: '.7rem',
                // padding: '5rem',
            },
        },
    };
}
